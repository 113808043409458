<template>
  <div class="main">
    <el-tabs type="border-card"  v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="任务统计" name="rw">
        <div style="display: flex;">
          <el-select v-model="listQuery.method" placeholder="查询类型" size="small" style="width: 100px" @change="selectChange(listQuery.method)">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-form style="margin:-4px 0px 0px 5px;width:500px" class="styleForm" :label-width="listQuery.method=='address' || listQuery.method=='addressDetail'?'60px':'80px'" size="small">
            <el-row :gutter="5">
              <el-col :span="12" v-if="listQuery.method=='address' || listQuery.method=='addressDetail'">
                <el-form-item label="地址：">   
                  <el-select v-model="listQuery.type" filterable placeholder="请选择地址" size="small" style="margin: 0;">
                    <el-option
                      v-for="item in addressList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
              </el-form-item>
              </el-col>
              <el-col :span="12" v-if="listQuery.method=='orgId' || listQuery.method=='orgIdDetail'">
                <el-form-item label="公司名称：" >   
                  <el-input v-model="listQuery.type" style="margin: 0;" placeholder="请输入公司名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时间：">
                  <el-date-picker
                    v-model="listQuery.date"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    value-format="YYYY-MM-DD"
                    style="width: 209px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div style="margin: -3px 0px 0px 10px;">
            <el-button @click="getList()" type="primary" size="small">
              <el-icon><Search /></el-icon> <span>搜索</span>
            </el-button>
            <el-button @click="cancel()" type="primary" size="small">
              <el-icon><RefreshLeft /></el-icon> <span>重置</span>
            </el-button>
          </div>
        </div>
        <div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="loading"
            :height="contentStyleObj"
            @select="handleSelectionChange"
            stripe
            show-summary
            @sort-change="sortChange"
            ref="tableScroll" 
            id="tableLazyLoad" 
            @select-all="handleSelectionChangeAll"
          >
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" prop="id" type="selection" width="55" />
            <el-table-column
              prop="name"
              :label="listQuery.method=='address'?'地址':'公司名称'"
              :width="listQuery.method=='address'?160:300"
              show-overflow-tooltip
              align="center"
              fixed
            />
            <el-table-column
              label="任务名称"
              min-width="220"
              show-overflow-tooltip
            >
              <template #default="scope">
                {{ $taskNameType(scope.row.taxName) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              label="总条数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="ok"
              label="成功"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="no1"
              label="异常（我们的）"
              align="center"
              width="140"
            />
            <el-table-column
              prop="no2"
              label="错误"
              align="center"
              width="100"
            />
            <el-table-column
              prop="taxRote"
              label="实际成功率"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.total - scope.row.no2 !== 0">
                  {{scope.row.taxRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taxRotes"
              label="成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.total !== 0">
                  {{scope.row.taxRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="时间" align="center" />
          </el-table>
        </div>
        <div class="pagination">
          <qzf-pagination
            v-show="total > 0"
            :total="total"
            v-model:page="listQuery.page"
            v-model:limit="listQuery.limit"
            @pagination="getList()"
            type="billStatistics"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="公司统计" name="gs">
        <div style="display: flex;">
          <el-form style="margin:-4px 0px 0px 5px;width:500px" class="styleForm" label-width="60px" size="small">
            <el-row :gutter="5">
              <el-col :span="12">
                <el-form-item label="地址：">   
                  <el-select v-model="listQuery.district" filterable placeholder="请选择地址" size="small" style="margin: 0;">
                    <el-option
                      v-for="item in addressList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
              </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时间：">
                  <el-date-picker
                    v-model="listQuery.date"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    value-format="YYYY-MM-DD"
                    style="width: 209px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            </el-form>
            <div style="margin: -3px 0px 0px 10px;">
              <el-button @click="getComList()" type="primary" size="small">
                <el-icon><Search /></el-icon> <span>搜索</span>
              </el-button>
              <el-button @click="cancel()" type="primary" size="small">
                <el-icon><RefreshLeft /></el-icon> <span>重置</span>
              </el-button>
            </div>
            <div style="margin-left: auto;">
              <el-button @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span>导出</span>
              </el-button>
            </div>
        </div>
        <div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="loading"
            :height="contentStyleObj"
            @select="handleSelectionChange"
            stripe
            @sort-change="sortChange"
            ref="tableScroll" 
            id="tableLazyLoad" 
            show-summary
            @select-all="handleSelectionChangeAll"
          >
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" prop="id" type="selection" width="55" />
            <el-table-column
              prop="name"
              label="地址"
              width="100"
              show-overflow-tooltip
              align="center"
              fixed
            />
            <el-table-column
              prop="sum"
              label="总使用数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="count"
              label="本月使用数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="ybCount"
              label="一般纳税人"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="taxTotal"
              label="总条数"
              align="center"
              min-width="90"
            />
            <el-table-column
              prop="taxOk"
              label="成功"
              align="center"
              width="80"
            />
            <el-table-column
              prop="taxNo1"
              label="异常（我们的）"
              align="center"
              width="130"
            />
            <el-table-column
              prop="taxNo2"
              label="错误"
              align="center"
              width="80"
            />
            <el-table-column
              prop="taxRote"
              label="实际成功率"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.taxTotal - scope.row.taxNo2 !== 0">
                  {{scope.row.taxRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taxRotes"
              label="成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.taxTotal !== 0">
                  {{scope.row.taxRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="gsTotal"
              label="个税总条数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="gsOk"
              label="个税成功"
              align="center"
              width="90"
            />
            <el-table-column
              prop="gsNo1"
              label="个税异常（我们的）"
              align="center"
              width="140"
            />
            <el-table-column
              prop="gsNo2"
              label="错误"
              align="center"
              width="80"
            />
            <el-table-column
              prop="gsRote"
              label="个税成功率"
              align="center"
              width="90"
            >
              <template #default="scope">
                <span v-if="scope.row.gsTotal - scope.row.gsNo2 !== 0">
                  {{ scope.row.gsRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sumTotal"
              label="所有任务数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumOk"
              label="所有成功数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumNo1"
              label="所有异常数（我们的）"
              align="center"
              width="150"
            />
            <el-table-column
              prop="sumNo2"
              label="所有错误数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumRote"
              label="实际所有成功率"
              align="center"
              width="140"
            >
              <template #default="scope">
                <span v-if="scope.row.sumTotal - scope.row.sumNo2 !== 0">
                  {{ scope.row.sumRote }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sumRotes"
              label="所有任务成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.sumTotal !== 0">
                  {{scope.row.sumRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="时间" width="160" align="center" />
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="明细统计" name="mx">
        <div style="display: flex;">
          <el-form style="margin:-4px 0px 0px 5px;width:500px" class="styleForm" label-width="60px" size="small">
            <el-row :gutter="5">
              <el-col :span="12">
                <el-form-item label="地址：">   
                  <el-select v-model="listQuery.district" filterable placeholder="请选择地址" size="small" style="margin: 0;">
                    <el-option
                      v-for="item in addressList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
              </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="时间：">
                  <el-date-picker
                    v-model="listQuery.date"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    value-format="YYYY-MM-DD"
                    style="width: 209px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            </el-form>
            <div style="margin: -3px 0px 0px 10px;">
              <el-button @click="getComList()" type="primary" size="small">
                <el-icon><Search /></el-icon> <span>搜索</span>
              </el-button>
              <el-button @click="cancel()" type="primary" size="small">
                <el-icon><RefreshLeft /></el-icon> <span>重置</span>
              </el-button>
            </div>
            <div style="margin-left: auto;">
              <el-button @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span>导出</span>
              </el-button>
            </div>
        </div>
        <div >
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="loading"
            :height="contentStyleObj"
            @select="handleSelectionChange"
            stripe
            @sort-change="sortChange"
            ref="tableScroll" 
            id="tableLazyLoad" 
            show-summary
            @select-all="handleSelectionChangeAll"
          >
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" prop="id" type="selection" width="55" />
            <el-table-column
              prop="name"
              label="地址"
              width="100"
              show-overflow-tooltip
              align="center"
              fixed
            />
            <el-table-column
              prop="sum"
              label="总使用数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="count"
              label="本月使用数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="ybCount"
              label="一般纳税人"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="taxTotal"
              label="总条数"
              align="center"
              min-width="90"
            />
            <el-table-column
              prop="taxOk"
              label="成功"
              align="center"
              width="80"
            />
            <el-table-column
              prop="taxNo1"
              label="异常（我们的）"
              align="center"
              width="130"
            />
            <el-table-column
              prop="taxNo2"
              label="错误"
              align="center"
              width="80"
            />
            <el-table-column
              prop="taxRote"
              label="实际成功率"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.taxTotal - scope.row.taxNo2 !== 0">
                  {{scope.row.taxRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taxRotes"
              label="成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.taxTotal !== 0">
                  {{scope.row.taxRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="gsTotal"
              label="个税总条数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="gsOk"
              label="个税成功"
              align="center"
              width="90"
            />
            <el-table-column
              prop="gsNo1"
              label="个税异常（我们的）"
              align="center"
              width="140"
            />
            <el-table-column
              prop="gsNo2"
              label="错误"
              align="center"
              width="80"
            />
            <el-table-column
              prop="gsRote"
              label="个税成功率"
              align="center"
              width="90"
            >
              <template #default="scope">
                <span v-if="scope.row.gsTotal - scope.row.gsNo2 !== 0">
                  {{ scope.row.gsRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sumTotal"
              label="所有任务数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumOk"
              label="所有成功数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumNo1"
              label="所有异常数（我们的）"
              align="center"
              width="150"
            />
            <el-table-column
              prop="sumNo2"
              label="所有错误数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumRote"
              label="实际所有成功率"
              align="center"
              width="140"
            >
              <template #default="scope">
                <span v-if="scope.row.sumTotal - scope.row.sumNo2 !== 0">
                  {{ scope.row.sumRote }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sumRotes"
              label="所有任务成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.sumTotal !== 0">
                  {{scope.row.sumRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="时间" width="160" align="center" />
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="当天数据统计" name="dateData">
        <div style="display: flex;">
          <el-select v-model="radioChart" placeholder="查询类型" size="small" style="width: 100px" @change="handleDateChange(radioChart)">
            <el-option
              v-for="item in radioChartList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-form style="margin:-4px 0px 0px 5px;width:250px" class="styleForm" label-width="60px" size="small">
              <el-form-item label="地址：">   
                <el-select v-model="listQuery.district" filterable placeholder="请选择地址" size="small">
                  <el-option
                    v-for="item in addressList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-form>
            <div style="margin: -3px 0px 0px 10px;">
              <el-button @click="getDataList()" type="primary" size="small">
                <el-icon><Search /></el-icon> <span>搜索</span>
              </el-button>
              <el-button @click="cancelData()" type="primary" size="small">
                <el-icon><RefreshLeft /></el-icon> <span>重置</span>
              </el-button>
            </div>
            <div style="margin-left: auto;">
              <el-button @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span>导出</span>
              </el-button>
            </div>
        </div>
        <div >
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="loading"
            :height="contentStyleObj"
            @select="handleSelectionChange"
            stripe
            @sort-change="sortChange"
            ref="tableScroll" 
            id="tableLazyLoad" 
            show-summary
            @select-all="handleSelectionChangeAll"
          >
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" prop="id" type="selection" width="55" />
            <el-table-column
              prop="name"
              label="地址"
              width="100"
              show-overflow-tooltip
              align="center"
              fixed
            />
            <el-table-column
              prop="sum"
              label="总使用数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="count"
              label="本月使用数"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="ybCount"
              label="一般纳税人"
              align="center"
              min-width="100"
            />
            <el-table-column
              prop="taxTotal"
              label="总条数"
              align="center"
              min-width="90"
            />
            <el-table-column
              prop="taxOk"
              label="成功"
              align="center"
              width="80"
            />
            <el-table-column
              prop="taxNo1"
              label="异常（我们的）"
              align="center"
              width="130"
            />
            <el-table-column
              prop="taxNo2"
              label="错误"
              align="center"
              width="80"
            />
            <el-table-column
              prop="taxRote"
              label="实际成功率"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.taxTotal - scope.row.taxNo2 !== 0">
                  {{scope.row.taxRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taxRotes"
              label="成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.taxTotal !== 0">
                  {{scope.row.taxRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="gsTotal"
              label="个税总条数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="gsOk"
              label="个税成功"
              align="center"
              width="90"
            />
            <el-table-column
              prop="gsNo1"
              label="个税异常（我们的）"
              align="center"
              width="140"
            />
            <el-table-column
              prop="gsNo2"
              label="错误"
              align="center"
              width="80"
            />
            <el-table-column
              prop="gsRote"
              label="个税成功率"
              align="center"
              width="90"
            >
              <template #default="scope">
                <span v-if="scope.row.gsTotal - scope.row.gsNo2 !== 0">
                  {{ scope.row.gsRote}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sumTotal"
              label="所有任务数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumOk"
              label="所有成功数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumNo1"
              label="所有异常数（我们的）"
              align="center"
              width="150"
            />
            <el-table-column
              prop="sumNo2"
              label="所有错误数"
              align="center"
              width="90"
            />
            <el-table-column
              prop="sumRote"
              label="实际所有成功率"
              align="center"
              width="140"
            >
              <template #default="scope">
                <span v-if="scope.row.sumTotal - scope.row.sumNo2 !== 0">
                  {{ scope.row.sumRote }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sumRotes"
              label="所有任务成功率"
              align="center"
              width="110"
            >
              <template #default="scope">
                <span v-if="scope.row.sumTotal !== 0">
                  {{scope.row.sumRotes}}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="时间" width="160" align="center" />
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="统计图" name="tj">
        <div style="display: flex;margin-bottom: 10px;">
          <el-select v-model="radioChart" placeholder="查询类型" size="small" style="width: 100px" @change="handleChange(radioChart)">
            <el-option
              v-for="item in radioChartList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <!-- <el-form style="margin:-4px 0px 0px 5px;width:200px" class="styleForm"  label-width="60px" size="small">
              <el-form-item label="时间：">
                <el-date-picker
                  v-model="listQuery.date"
                  type="month"
                  placeholder="选择日期"
                  size="small"
                  value-format="YYYY-MM-DD"
                  style="width: 209px"
                />
              </el-form-item>
          </el-form>
          <div style="margin: -3px 0px 0px 10px;">
            <el-button @click="getSearch()" type="primary" size="small">
              <el-icon><Search /></el-icon> <span>搜索</span>
            </el-button>
            <el-button @click="cancelSearch()" type="primary" size="small">
              <el-icon><RefreshLeft /></el-icon> <span>重置</span>
            </el-button>
          </div> -->
        </div>
        <el-scrollbar :style="{ height: contentStyleObjs }" v-loading="loading">
          <div >
            <lineChart ref="taxsumChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="taxCgllineChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="taxSjcgllineChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="gsSumlineChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="gsCgllineChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="sumlineChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="cgllineChart" ></lineChart>
          </div>
          <div style="margin-top: 20px;" >
            <lineChart ref="sjcgllineChart" ></lineChart>
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <!-- <div class="pagination" v-if="statType">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getComList()"
        type="billStatistics"
      />
    </div> -->
  </div>
</template>

<script>
import { exportBTax } from "@/api/export";
import { taxTaskStatisticsList,eaCompanyStatisticsList,taxEaCompanyDatailStatisticsList,getAddress,zxTaxEaCompanyStatisticsList,taxEaCompanyDtDatailStatisticsList,taxEaCompanyDtStatisticsList } from "@/api/company";
import lineChart from "./components/lineChart";
export default {
  name: "taskStatistics",
  props: {},
  components: {
    lineChart,
  },
  data() {
    return {
      activeName:'rw',//tabes标签
      // 存放查询数据
      listQuery: {
        page: 1,
        limit: 20,
        method:'',
        type:'',
        startDate:'',
        date:'',
        endDate:'',
        district:'',
      },
      addressList:[],
      // 公司统计
      statType:false,
      // 遮罩层
      loading: false,
      options:[
        {
          value: 'address',
          label: '地址',
        },
        {
          value: 'addressDetail',
          label: '地址明细',
        },
        {
          value: 'orgId',
          label: '记账',
        },
        {
          value: 'orgIdDetail',
          label: '记账明细',
        },
      ],
      method: '',
      tableData: [],
      total: 0,
      ids: [],
      totalPage:0,
      pageSize:50,
      radioChart:'gstj',//折线统计radio
      radioChartList:[
        {
          value: 'gstj',
          label: '公司统计',
        },
        {
          value: 'mxtj',
          label: '明细统计',
        },
      ],
      datazxl:{},
      tableDatas:{},
      contentStyleObj: {},
      contentStyleObjs: {},
    }
  },
  created() {
    this.listQuery.limit = localStorage.getItem("taskStatistics")
      ? localStorage.getItem("taskStatistics") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(300);
    this.contentStyleObjs = this.$getHeight(200);
  },
  mounted() {
    this.listQuery.method = 'address'
    this.method = this.listQuery.method
    this.getList(this.listQuery.limit);
  },
  methods: {
    getDaysArray(year, month) {
      const startDate = new Date(year, month - 1, 1); // 设置为月份的第一天
      const endDate = new Date(year, month, 0); // 设置为下个月的第零天

      const daysArray = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        daysArray.push(currentDate.getDate()+'日');
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return daysArray;
    },

    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        type:'',
        startDate:'',
        endDate:'',
        district:'',
        date:'',
      }
      this.listQuery.method = this.method
      if(this.activeName=='rw'){
        this.getList()
      }else{
        this.getComList();
      }
    },
    cancelSearch(){
      this.listQuery={
        page: 1,
        type:'',
        startDate:'',
        endDate:'',
        district:'',
        date:'',
      }
      this.handleChange(this.radioChart)
    },
    cancelData(){
      this.listQuery={
        page: 1,
        type:'',
        startDate:'',
        endDate:'',
        district:'',
        date:'',
      }
      this.getDataList()
    },
    getList() {
      this.loading = true;
      taxTaskStatisticsList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.tableData.forEach((item,i) => {
            if(item.total !== 0){//成功率
              this.tableData[i].taxRotes = ((item.ok / item.total) * 100).toFixed(2)+'%';
            }
            if(item.total - item.no2 !== 0){//实际成功率
              this.tableData[i].taxRote =((item.ok / (item.total - item.no2)) * 100).toFixed(2)+'%';
            }
          });
          this.total = res.data.data.total
          getAddress({}).then(res=>{
            if(res.data.data.list){
              this.addressList = res.data.data.list
            } 
          })
          this.setScroll()
        }
      });
    },
    getComList() {
      this.loading = true;
      if(this.activeName == 'gs'){
        eaCompanyStatisticsList(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.tableData.forEach((item,i) => {
              if(item.taxTotal !== 0){//成功率
                this.tableData[i].taxRotes = ((item.taxOk / item.taxTotal) * 100).toFixed(2)+'%';
              }
              if(item.taxTotal - item.taxNo2 !== 0){//实际成功率
                this.tableData[i].taxRote =((item.taxOk / (item.taxTotal - item.taxNo2)) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal - item.sumNo2 !== 0){//实际所有成功率
                this.tableData[i].sumRote =((item.sumOk / (item.sumTotal - item.sumNo2)) * 100).toFixed(2)+'%';
              }
              if(item.gsTotal - item.gsNo2 !== 0){//个税成功率
                this.tableData[i].gsRote = ((item.gsOk / (item.gsTotal - item.gsNo2)) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal !== 0){//所有成功率
                this.tableData[i].sumRotes = ((item.sumOk / item.sumTotal) * 100).toFixed(2)+'%';
              }
            });
            this.loading = false;
            // taxRate
            this.total = res.data.data.total
            this.setScroll()
          }
        });
      }else{
        taxEaCompanyDatailStatisticsList(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.tableData.forEach((item,i) => {
              if(item.taxTotal !== 0){//成功率
                this.tableData[i].taxRotes = ((item.taxOk / item.taxTotal) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal - item.sumNo2 !== 0){//实际所有成功率
                this.tableData[i].sumRote =((item.sumOk / (item.sumTotal - item.sumNo2)) * 100).toFixed(2)+'%';
              }
              if(item.gsTotal - item.gsNo2 !== 0){//个税成功率
                this.tableData[i].gsRote = ((item.gsOk / (item.gsTotal - item.gsNo2)) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal !== 0){//所有成功率
                this.tableData[i].sumRotes = ((item.sumOk / item.sumTotal) * 100).toFixed(2)+'%';
              }
              if(item.taxTotal - item.taxNo2 !== 0){//实际成功率
                this.tableData[i].taxRote =((item.taxOk / (item.taxTotal - item.taxNo2)) * 100).toFixed(2)+'%';
              }
            });
            this.loading = false;
            // taxRate
            this.total = res.data.data.total
            this.setScroll()
          }
        });
      }
    },
    // 查询条件
    selectChange(value){
      this.listQuery={
        page: 1,
        limit: 20,
        method:'',
        type:'',
        startDate:'',
        endDate:'',
        district:'',
        date:'',
      },
      this.listQuery.method = value
      this.method = this.listQuery.method
      this.getList()
    },
    // 折线图搜索
    getSearch(){
      this.handleChange(this.radioChart)
    },
    // 切换标签
    handleClick(tab){
      this.activeName = tab.props.name
      if(tab.props.name =='rw' ){
        this.listQuery.method = 'address'
        this.getList() 
      }else if(tab.props.name =='gs' || tab.props.name =='mx'){
        let originLimit = this.listQuery.limit
        this.listQuery={
          page: 1,
          limit: originLimit,
          type:'',
          startDate:'',
          endDate:'',
          district:'',
         }
        this.listQuery.method = this.method
        this.$nextTick(()=>{
          this.getComList()
        })
      }else if(tab.props.name =='tj'){
        this.radioChart = 'gstj'
        this.handleChange(this.radioChart)
      }else{
        this.radioChart = 'gstj'
        this.handleDateChange(this.radioChart)
      }
    },
    // 查询当天数据
    getDataList(){
      if(this.radioChart == 'gstj'){
        taxEaCompanyDtStatisticsList(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.tableData.forEach((item,i) => {
              if(item.taxTotal !== 0){//成功率
                this.tableData[i].taxRotes = ((item.taxOk / item.taxTotal) * 100).toFixed(2)+'%';
              }
              if(item.taxTotal - item.taxNo2 !== 0){//实际成功率
                this.tableData[i].taxRote =((item.taxOk / (item.taxTotal - item.taxNo2)) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal - item.sumNo2 !== 0){//实际所有成功率
                this.tableData[i].sumRote =((item.sumOk / (item.sumTotal - item.sumNo2)) * 100).toFixed(2)+'%';
              }
              if(item.gsTotal - item.gsNo2 !== 0){//个税成功率
                this.tableData[i].gsRote = ((item.gsOk / (item.gsTotal - item.gsNo2)) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal !== 0){//所有成功率
                this.tableData[i].sumRotes = ((item.sumOk / item.sumTotal) * 100).toFixed(2)+'%';
              }
            });
            this.loading = false;
            // taxRate
            this.total = res.data.data.total
            this.setScroll()
          }
        });
      }else{
        taxEaCompanyDtDatailStatisticsList(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.tableData.forEach((item,i) => {
              if(item.taxTotal !== 0){//成功率
                this.tableData[i].taxRotes = ((item.taxOk / item.taxTotal) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal - item.sumNo2 !== 0){//实际所有成功率
                this.tableData[i].sumRote =((item.sumOk / (item.sumTotal - item.sumNo2)) * 100).toFixed(2)+'%';
              }
              if(item.gsTotal - item.gsNo2 !== 0){//个税成功率
                this.tableData[i].gsRote = ((item.gsOk / (item.gsTotal - item.gsNo2)) * 100).toFixed(2)+'%';
              }
              if(item.sumTotal !== 0){//所有成功率
                this.tableData[i].sumRotes = ((item.sumOk / item.sumTotal) * 100).toFixed(2)+'%';
              }
              if(item.taxTotal - item.taxNo2 !== 0){//实际成功率
                this.tableData[i].taxRote =((item.taxOk / (item.taxTotal - item.taxNo2)) * 100).toFixed(2)+'%';
              }
            });
            this.loading = false;
            // taxRate
            this.total = res.data.data.total
            this.setScroll()
          }
        });
      }
    },
    // 当天数据切换select
    handleDateChange(){
      this.loading = true;
      this.getDataList()
    },
    // 折线图
    handleChange(tab){
      this.tableData=[]
      if(tab == 'gstj'){
        zxTaxEaCompanyStatisticsList(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            this.tableDatas = res.data.data ? res.data.data : []
            if(this.tableDatas.taxSum){
              let datazxl={}
              datazxl.type ='taxsum'
              datazxl.list = this.tableDatas.taxSum
              this.$refs.taxsumChart.init(datazxl);
            }
            if(this.tableDatas.taxCgl){
              let datazxl={}
              datazxl.type ='taxCgl'
              datazxl.list = this.tableDatas.taxCgl
              this.$refs.taxCgllineChart.init(datazxl);
            }
            if(this.tableDatas.taxSjcgl){
              let aa={}
              aa.type ='taxSjcgl'
              aa.list = this.tableDatas.taxSjcgl
              this.$refs.taxSjcgllineChart.init(aa);
            }
            if(this.tableDatas.gsSum){
              let datazxl={}
              datazxl.type ='gsSum'
              datazxl.list = this.tableDatas.gsSum
              this.$refs.gsSumlineChart.init(datazxl);
            }
            if(this.tableDatas.gsCgl){
              let datazxl={}
              datazxl.type ='gsCgl'
              datazxl.list = this.tableDatas.gsCgl
              this.$refs.gsCgllineChart.init(datazxl);
            }
            if(this.tableDatas.sum){
              let datazxl={}
              datazxl.type ='sum'
              datazxl.list = this.tableDatas.sum
              this.$refs.sumlineChart.init(datazxl);
            }
            if(this.tableDatas.cgl){
              let datazxl={}
              datazxl.type ='cgl'
              datazxl.list = this.tableDatas.cgl
              this.$refs.cgllineChart.init(datazxl);
            }
            if(this.tableDatas.sjcgl){
              let datazxl={}
              datazxl.type ='sjcgl'
              datazxl.list = this.tableDatas.sjcgl
              this.$refs.sjcgllineChart.init(datazxl);
            }
            // this.$refs.lineChartSum.init(this.datazxl);
          }
        });
      
      }else{
        taxEaCompanyDtStatisticsList(this.listQuery).then((res) => {
          if (res.data.msg == "success") {
            this.tableDatas = res.data.data ? res.data.data : []
            if(this.tableDatas.taxSum){
              let datazxl={}
              datazxl.type ='taxsum'
              datazxl.list = this.tableDatas.taxSum
              this.$refs.taxsumChart.init(datazxl);
            }
            if(this.tableDatas.taxCgl){
              let datazxl={}
              datazxl.type ='taxCgl'
              datazxl.list = this.tableDatas.taxCgl
              this.$refs.taxCgllineChart.init(datazxl);
            }
            if(this.tableDatas.taxSjcgl){
              let aa={}
              aa.type ='taxSjcgl'
              aa.list = this.tableDatas.taxSjcgl
              this.$refs.taxSjcgllineChart.init(aa);
            }
            if(this.tableDatas.gsSum){
              let datazxl={}
              datazxl.type ='gsSum'
              datazxl.list = this.tableDatas.gsSum
              this.$refs.gsSumlineChart.init(datazxl);
            }
            if(this.tableDatas.gsCgl){
              let datazxl={}
              datazxl.type ='gsCgl'
              datazxl.list = this.tableDatas.gsCgl
              this.$refs.gsCgllineChart.init(datazxl);
            }
            if(this.tableDatas.sum){
              let datazxl={}
              datazxl.type ='sum'
              datazxl.list = this.tableDatas.sum
              this.$refs.sumlineChart.init(datazxl);
            }
            if(this.tableDatas.cgl){
              let datazxl={}
              datazxl.type ='cgl'
              datazxl.list = this.tableDatas.cgl
              this.$refs.cgllineChart.init(datazxl);
            }
            if(this.tableDatas.sjcgl){
              let datazxl={}
              datazxl.type ='sjcgl'
              datazxl.list = this.tableDatas.sjcgl
              this.$refs.sjcgllineChart.init(datazxl);
            }
            // this.$refs.lineChartSum.init(this.datazxl);
          }
        });
       
      }
    },

    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getIds(){
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.comId);
      });
      this.ids = ids;
    },
    // 筛选打印
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.allChecked = true
        this.getIds()
      }else{
        this.selects = []
        this.ids = []
        this.allChecked = false
      }
    },
    //导出
    daochu() {
      exportBTax(this.tableData).then((res) => {
        console.log(res);
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.app-container{
  padding: 0px 0px 0px 0px!important;
}
.content_data {
  background-color: #d8ecff;
}
.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}
.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.iconfont {
  font-size: 18px;
  //color: var(--themeColor, #17a2b8);
  color: #17a2b8;
}
.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.totalAll{
  float: right;
  font-weight: bold;
}
</style>
