<template>
  <div :id="type" ref="echarts" style="width:100%;height:330px;border: 1px solid #d7d7d7;padding: 10px;" :style="{height:contentStyleObj}"></div>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';
export default {
  name:'lineChart',
  props:{
  },
  data() {
    return {
      contentStyleObj:{},
      date:[],
      direct:[],
      type:""
    }
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(360)
  },
  methods:{
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list){
      // console.log(list);
      let titleCharts = ''
      this.type = list.type
      if(list.type == 'taxsum'){
        titleCharts = '税局所有使用条数统计图'
      }else if(list.type == 'taxCgl'){
        titleCharts = '税局成功率统计图'
      }else if(list.type == 'taxSjcgl'){
        titleCharts = '税局实际成功率统计图'
      }else if(list.type == 'gsSum'){
        titleCharts = '个税使用条数统计图'
      }else if(list.type == 'gsCgl'){
        titleCharts = '个税成功率统计图'
      }else if(list.type == 'sum'){
        titleCharts = '所有使用条数统计图'
      }else if(list.type == 'cgl'){
        titleCharts = '所有成功率统计图'
      }else if(list.type == 'sjcgl'){
        titleCharts = '所有实际成功率统计图'
      }
      let taxSum = list.list
      // 提取地区和日期
      let regions = Object.keys(taxSum);
      let dates = Object.keys(taxSum[regions[0]]);

      // 构造数据
      let seriesData = [];
      if(list.type == 'taxCgl' || list.type == 'taxSjcgl' || list.type == 'gsCgl' || list.type == 'cgl' || list.type == 'sjcgl'){
        regions.forEach(function(region) {
          let regionData = [];
          dates.forEach(function(date) {
            regionData.push({
              value: taxSum[region][date] * 100,
              label: {
                show: true,
                formatter: function(params) {
                  return params.value;
                }
              }
            });
          });
          seriesData.push({
            name: region,
            type: 'line',
            data: regionData
          });
        });
      }else{
        regions.forEach(function(region) {
          let regionData = [];
          dates.forEach(function(date) {
            regionData.push({
              value: taxSum[region][date],
              label: {
                show: true,
                formatter: function(params) {
                  return params.value;
                }
              }
            });
          });
          seriesData.push({
            name: region,
            type: 'line',
            data: regionData
          });
        });
      }
      // console.log(seriesData);
      // var myChart = echarts.init(document.getElementById('lineChart'));
      var myChart = echarts.init(this.$refs.echarts);

      myChart.setOption({
        title: {
          text: titleCharts,
          left: 'center'
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: regions,
          top: 40,
          x: 'left'
        },
        grid: {
          left: "1%",
          top: 120,
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dates,
          
        },
        yAxis: {
          type: "value"
        },
        series: seriesData
      })
      window.onresize = function(){
        myChart.resize()
      }
    }
  }
}
</script>

<style>

</style>